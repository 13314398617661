
export const WebProVpn = {
    webWin: 'https://store.bittorrent.com/849/purl-btwebprovpnwebwinreason',
    webMac: 'https://store.bittorrent.com/849/purl-btwebprovpnwebmacreason',
}
export const WebPro = {
    webWin: 'https://store.bittorrent.com/849/purl-btwprowebwinreason',
    webMac: 'https://store.bittorrent.com/849/purl-btwebprowebmacreason',
}
export const WebAf = {
    webWin: 'https://store.bittorrent.com/849/purl-btwafwebwin',
    webMac: 'https://store.bittorrent.com/849/purl-btwafwebmac',
}
