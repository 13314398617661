import React, {useEffect, useState, useContext} from 'react';
import { Link } from "gatsby"
import useTranslations from "../../utils/useTranslations"
import { FaWindows } from 'react-icons/fa';
import Button from "../../components/button"
import fetchCherryData from "../../utils/cherry"
import Logo from "../../components/logo"
import btwebScreenshot from "../../images/downloads/btweb-windows-screen.png"
import btwebScreenshot2x from "../../images/downloads/btweb-windows-screen@2x.png"
import btclassicScreenshot from "../../images/downloads/btclassic-windows-screen.jpg"
import btclassicScreenshot2x from "../../images/downloads/btclassic-windows-screen@2x.jpg"
import btclassicScreenshot3x from "../../images/downloads/btclassic-windows-screen@3x.jpg"

import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { CompatibilityContext } from "../../components/compatibilityContext";
import { getPromoLinks, promoPrices, getShowPromoDiscount } from '../../components/promo'
import {WebPro, WebProVpn} from "../../components/cartLinks";

const langPackWin = "https://legacy.utorrent.com/scripts/dl.php?track=stable&build=30544&client=bittorrent"
var webProLink          = WebPro.webWin
var webProVPNLink       = WebProVpn.webWin
var classicProVPNLink   = `https://store.bittorrent.com/849/purl-btwebprovpn6995?x-source=classicprovpn-downloads`
var classicProLink      = `https://store.bittorrent.com/849/purl-btproweb?x-source=classicpro-downloads`
const classicAdFreeLink = `https://store.bittorrent.com/849/purl-btafwebw?x-source=classicadfree-downloads`

var proVpnPrice = "$69.95"
var proPrice = "$19.95"
var adFreePrice = "$4.95"

const DownloadsWindows = ({ pageContext: {text, locale} }) => {

	const [proVpnPrice2, setproVpnPrice2] = useState(proVpnPrice)
	const [proPrice2, setproPrice2] = useState(proPrice)
	const [adFreePrice2, setadFreePrice2] = useState(adFreePrice)

	const [webProLink2, setwebProLink2] = useState(webProLink)
	const [webProVPNLink2, setwebProVPNLink2] = useState(webProVPNLink)
	const [classicProVPNLink2, setclassicProVPNLink2] = useState(classicProVPNLink)
	const [classicProLink2, setclassicProLink2] = useState(classicProLink)
	const [classicAdFreeLink2, setclassicAdFreeLink2] = useState(classicAdFreeLink)

	const showPromoDiscount = getShowPromoDiscount();
	const showProVpnPromo = typeof showPromoDiscount === 'boolean' ? showPromoDiscount : showPromoDiscount.proVpn;
	const showProPromo = typeof showPromoDiscount === 'boolean' ? showPromoDiscount : showPromoDiscount.pro;
	const showAdFreePromo = typeof showPromoDiscount === 'boolean' ? showPromoDiscount : showPromoDiscount.adFree;
	const promoPercent = promoPrices.percent;

	useEffect(() => {
		if (showProVpnPromo) {
			let newLinks = getPromoLinks({webProVPNLink, classicProVPNLink});
			setwebProVPNLink2(newLinks.webProVPNLink);
			setclassicProVPNLink2(newLinks.classicProVPNLink);
		}
	}, [showProVpnPromo]);

	useEffect(() => {
		if (showProPromo) {
			let newLinks = getPromoLinks({webProLink, classicProLink});
			setwebProLink2(newLinks.webProLink);
			setclassicProLink2(newLinks.classicProLink);
		}
	}, [showProPromo]);

	useEffect(() => {
		if (showAdFreePromo) {
			let newLinks = getPromoLinks({classicAdFreeLink});
			setclassicAdFreeLink2(newLinks.classicAdFreeLink);
		}
	}, [showAdFreePromo]);

	const t = useTranslations(text)
  
  	const [dataClassic, setDataClassic] = useState({});
  	const [dataWeb, setDataWeb] = useState({});

	useEffect(() => {
			fetchCherryData("bittorrent", "windows", locale)
			.then((res) => {
				setDataClassic(res)
			})

			fetchCherryData("btweb", "winxp,winvista,win7,win8,win81,win10,windows,windows64", locale)
			.then((res) => {
				setDataWeb(res)
			})

		},[locale]
	)

	const [modal, setModal] = useState(false);
	const [modalType, setModalType] = useState("");

	const toggle = (modalType) => {
		setModalType(modalType)
		setModal(!modal)
	}

	const compatibilityHandler = useContext(CompatibilityContext)
	function handleClick(e, action, product, link){
		compatibilityHandler(e, action, product, ["Win"], link)
	}

	const webLink = "/products/win/bittorrent-web-compare/"
	const classicLink = "/products/win/bittorrent-classic-compare/"

	const langPackSize = "(1.2 MB)"
	const langPackDate = "February 21, 2014"
	let langPackD = new Date(langPackDate)
	langPackD = langPackD.toLocaleDateString(locale.replace('_','-'))

  	return (
		<div className="container mt-5 mb-5 pt-5">
			<div className="row">
				<div className="col-12 text-center text-md-left">
					<h1><FaWindows className="mb-2" />  <span className="ml-2">{t("BitTorrent Downloads for Windows")}</span></h1>
					<div className="text-size-24 mt-2 mb-4 text-muted">{t("The original torrenting software for Windows, trusted by millions.")}</div>
				</div>
			</div>
			<div className="row mt-5">
				<div className="col-md-7 order-2 order-md-1 text-center text-md-left">
					<div className="mb-2 d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
						<div className="d-flex flex-column flex-md-row justify-content-center align-items-center text-nowrap">
		              		<Logo color="black" tag="h2" productName="Web" className="text-center text-md-left mb-0" fsMax={21.5} fixedParentDistance={3}/>
		              		<div className="ml-0 ml-md-5 mb-3 mb-md-0">(Stable {dataWeb.version})</div>
		              	</div>
					</div>
					<p className="text-size-20 mb-2 text-muted">{t("An easy-to-use online torrent downloader and player.")}</p>
					<p>{t("For Windows")} ({dataWeb.fileSize}) | {t("English (US) –")} {dataWeb.lastModified}</p>
					<Button href={webLink} id="win-downloads-webfree" className="btn-primary button-filled text-spacing-1">{t("Free Download")}</Button>
				</div>
				<div className="col-md-5 order-1 order-md-2 mb-md-0 text-center text-md-right">
					<img src={btwebScreenshot} srcSet={`${btwebScreenshot2x} 2x`} alt="BitTorrent Web Screenshot" className="img-fluid w-100"></img>
	                <div style={{fontSize: '10px'}} className="mt-2 mb-4 text-center"><a id="downloads-win-bt-now" href="https://now.bt.co/" target="_blank" rel="noopenner noreferrer" className="text-dark text-decoration-none">now.bt.co</a> content displayed pursuant to license.</div>
				</div>
				<div className="col-12 order-3 mt-5">
					<hr style={{ margin: `10px 0 10px 0` }} />
				</div>
			</div>
			<div className="row mt-5">
				<div className="col-md-7 order-2 order-md-1 text-center text-md-left">
					<div className="mb-2 d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
		              	<div className="d-flex flex-column flex-md-row justify-content-center align-items-center text-nowrap">
		              		<Logo color="black" tag="h2" productName="Classic" className="text-center text-md-left mb-0" fsMax={22} fixedParentDistance={3}/>
		              		<div className="ml-0 ml-md-5 mb-3 mb-md-0">(Stable {dataClassic.version})</div>
		              	</div>
					</div>
					<p className="text-size-20 mb-2 text-muted">{t("The original desktop torrent <span className='d-block d-sm-inline-block'>client for Windows.</span>")}</p>
					<p>{t("For Windows")} ({dataClassic.fileSize}) | {t("English (US) –")} {dataClassic.lastModified} </p>
					<Button href={classicLink} id="win-downloads-classicfree" className="btn-primary button-filled text-spacing-1">{t("Free Download")}</Button>
				</div>
				<div className="col-md-5 order-1 order-md-2 mb-4 mb-md-0 text-center text-md-right">
					<img src={btclassicScreenshot} srcSet={`${btclassicScreenshot2x} 2x, ${btclassicScreenshot3x} 3x`} alt="BitTorrent Classic Screenshot" className="img-fluid w-100"></img>
				</div>
				<div className="col-12 order-3 mt-5">
					<hr style={{ margin: `10px 0 10px 0` }} />
				</div>
			</div>
			<div className="row mt-3">
				<div className="col-12 text-center text-md-left">
					<h2 className="text-size-16 mb-2">
						<Link to="/blog/releases/windows/" id="downloads-windows-releasenotes" className="text-decoration-none text-body">
                          <u>{t("Release Notes")}</u>
                        </Link>
					</h2>
					<div>
						<span className="d-block mb-2">
							<a id="downloads-windows-langpack" className="text-dark" href={langPackWin}>{t(`Download language pack`)} {langPackSize} - {langPackD}</a> | <a id="downloads-windows-availlangs" className="underline pointer" onClick={()=>{toggle("Languages")}}>{t(`Available Languages`)}</a> | <a id="downloads-windows-thx-trans" className="underline pointer" onClick={()=>{toggle("Translators")}} >{t(`Thanks to our Translators`)}</a>
						</span>
						<p className='d-block d-sm-inline-block'><i>To install, place BitTorrent.lng in %AppData%\\BitTorrent</i></p>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12 text-center text-md-left">
					<h2 className="mt-5 text-size-24">{t("Premium Safe Torrenting Software for Windows")}</h2>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="w-100 h-100 my-2 d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
		              	<div className="text-size-20 text-center text-md-left"><span className="font-weight-bold bt-font">BitTorrent</span> Web Pro</div>
		              	<div className="flex-shrink-0">
							{showProPromo &&
								<span className="text-danger text-uppercase">{promoPercent} {t("Off")} &nbsp;</span>
							}
							<span className={showProPromo ? 'text-decoration-line-through' : ''}>{proPrice2}</span>
							&nbsp;&nbsp;|&nbsp;&nbsp; <a onClick={(e) => {handleClick(e, "buying", "Web", webProLink2)}} href={webProLink2} id="win-downloads-webpro" className="text-body text-spacing-1 font-weight-bold" rel="noopener noreferrer">{t("Buy Now >")}</a>
		              	</div>
					</div>
				</div>
				<div className="col-12">
					<div className="w-100 h-100 my-2 d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
		              	<div className="text-size-20 text-center text-md-left"><span className="font-weight-bold bt-font">BitTorrent</span> Web Pro+VPN</div>
		              	<div className="flex-shrink-0">
							{showProVpnPromo &&
								<span className="text-danger text-uppercase">{promoPercent} {t("Off")} &nbsp;</span>
							}
							<span className={showProVpnPromo ? 'text-decoration-line-through' : ''}>{proVpnPrice2}</span>
							&nbsp;&nbsp;|&nbsp;&nbsp; <a onClick={(e) => {handleClick(e, "buying", "Web", webProVPNLink2)}} href={webProVPNLink2} id="win-downloads-webprovpn" className="text-body text-spacing-1 font-weight-bold" rel="noopener noreferrer">{t("Buy Now >")}</a>
		              	</div>
					</div>
				</div>
				<div className="col-12">
					<div className="w-100 h-100 my-2 d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
						<div className="text-size-20 text-center text-md-left"><span className="font-weight-bold bt-font">BitTorrent</span> Classic Pro</div>
						<div className="flex-shrink-0">
							{showProPromo &&
								<span className="text-danger text-uppercase">{promoPercent} {t("Off")} &nbsp;</span>
							}
							<span className={showProPromo ? 'text-decoration-line-through' : ''}>{proPrice2}</span>
							&nbsp;&nbsp;|&nbsp;&nbsp; <a onClick={(e) => {handleClick(e, "buying", "Classic", classicProLink2)}} href={classicProLink2} id="win-downloads-classicpro" className="text-body text-spacing-1 font-weight-bold" rel="noopener noreferrer">{t("Buy Now >")}</a>
						</div>
					</div>
				</div>
				<div className="col-12">
					<div className="w-100 h-100 my-2 d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
						<div className="text-size-20 text-center text-md-left"><span className="font-weight-bold bt-font">BitTorrent</span> Classic Ad-Free</div>
						<div className="flex-shrink-0">
							{showAdFreePromo &&
								<span className="text-danger text-uppercase">{promoPercent} {t("Off")} &nbsp;</span>
							}
							<span className={showAdFreePromo ? 'text-decoration-line-through' : ''}>{adFreePrice2}</span>
							&nbsp;&nbsp;|&nbsp;&nbsp; <a onClick={(e) => {handleClick(e, "buying", "Classic", classicAdFreeLink2)}} href={classicAdFreeLink2} id="win-downloads-classicaf" className="text-body text-spacing-1 font-weight-bold" rel="noopener noreferrer">{t("Buy Now >")}</a>
						</div>
					</div>
				</div>
				<div className="col-12 mb-5">
					<div className="w-100 h-100 my-2 d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
		              	<div className="text-size-20 text-center text-md-left"><span className="font-weight-bold bt-font">BitTorrent</span> Classic Pro+VPN</div>
		              	<div className="flex-shrink-0">
							{showProVpnPromo &&
								<span className="text-danger text-uppercase">{promoPercent} {t("Off")} &nbsp;</span>
							}
							<span className={showProVpnPromo ? 'text-decoration-line-through' : ''}>{proVpnPrice2}</span>
							&nbsp;&nbsp;|&nbsp;&nbsp; <a onClick={(e) => {handleClick(e, "buying", "Classic", classicProVPNLink2)}} href={classicProVPNLink2} id="win-downloads-classicprovpn" className="text-body text-spacing-1 font-weight-bold" rel="noopener noreferrer">{t("Buy Now >")}</a>
		              	</div>
					</div>
				</div>
			</div>

			<DownloadModals
				isOpen={modal}
				toggle={toggle}
				type={modalType}
			/>
 

		</div>
 	)
}

const DownloadModals = (props) => {
	let isLanguages = (props.type === "Languages") ? true:false;
	let isTranslators = (props.type === "Translators") ? true:false;

	return (
      <Modal isOpen={props.isOpen} toggle={()=>{props.toggle('')}} className="d-flex">
        <ModalHeader toggle={()=>{props.toggle('')}}>{props.type}</ModalHeader>
        <ModalBody>
        	{isLanguages && 
		        <div className="row px-3" >
					<ul className="col-md-3 list-unstyled">
						<li>Arabic</li>
						<li>Awadh</li>
						<li>Armenian</li>
						<li>Azerbaijani</li>
						<li>Basque</li>
						<li>Belarusian</li>
						<li>Bengali</li>
						<li>Bosnian</li>
						<li>Bulgarian</li>
						<li>Catalan</li>
						<li>Chinese (Simplified)</li>
						<li>Chinese (Traditional)</li>
						<li>Croatian</li>
						<li>Czech</li>
						<li>Danish</li>
						<li>Dutch</li>
						<li>Esperanto</li>
					</ul>
					<ul className="col-md-3 list-unstyled">
						<li>Estonian</li>
						<li>Finnish</li>
						<li>French</li>
						<li>Gaeilge</li>
						<li>Georgian</li>
						<li>Greek</li>
						<li>Hebrew</li>
						<li>Hindi</li>
						<li>Hungarian</li>
						<li>Icelandic</li>
						<li>Indonesian</li>
						<li>Italian</li>
						<li>Japanese</li>
						<li>Kannada</li>
						<li>Kazakh</li>
						<li>Khmer</li>
						<li>Korean</li>
						<li>Kurdish</li>
					</ul>
					<ul className="col-md-3 list-unstyled">
						<li>Kyrgyz</li>
						<li>Latvian</li>
						<li>Lithuanian</li>
						<li>Macedonian</li>
						<li>Malay</li>
						<li>Malayalam</li>
						<li>Marathi</li>
						<li>Mongolian</li>
						<li>Norwegian</li>
						<li>Norwegian Nynorsk</li>
						<li>Persian</li>
						<li>Polish</li>
						<li>Portuguese (Brazil)</li>
						<li>Portuguese (Portugal)</li>
					</ul>
					<ul className="col-md-3 list-unstyled">
						<li>Romanian</li>
						<li>Russian</li>
						<li>Serbian (Cyrillic)</li>
						<li>Serbian (Latin)</li>
						<li>Sinhala</li>
						<li>Slovak</li>
						<li>Slovenian</li>
						<li>Spanish</li>
						<li>Swedish</li>
						<li>Taiwan</li>
						<li>Tamil</li>
						<li>Thai</li>
						<li>Turkish</li>
						<li>Ukrainian</li>
						<li>Urdu</li>
						<li>Vietnamese</li>
						<li>Welsh</li>
					</ul>
				</div>
        	}

        	{isTranslators &&
				<div className="row downloads-modal">
						<div className="col-md-3">
							<div ><p className="mb-0">Arabic</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=93012">Awadh</a></li></ul>
							</div>
							<div ><p className="mb-0">Armenian</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=22139">Hrant</a></li></ul>
							</div>
							<div ><p className="mb-0">Azerbaijani</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=48547">Ugur Eminli</a></li></ul>
							</div>

							<div ><p className="mb-0">Bangla (Bengali)</p>
								<ul>
									<li><a href="http://forum.utorrent.com/profile.php?id=933664">ph3n1x</a></li>
									<li><a href="http://forum.utorrent.com/profile.php?id=849002">heavengod</a></li>
									<li><a href="http://forum.utorrent.com/profile.php?id=486852">royx117</a></li>
								</ul>
							</div>

							<div ><p className="mb-0">Basque</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=826026">Azpidatziak</a></li></ul>
							</div>

							<div ><p className="mb-0">Belarusian</p>
								<ul><li>beltranslator</li></ul>
							</div>
			
							<div ><p className="mb-0">Bosnian</p>
								<ul><li>
									<a href="http://forum.utorrent.com/profile.php?id=37533">nerko</a>
								</li></ul>
							</div>

							<div ><p className="mb-0">Bulgarian</p>
								<ul><li>
									<a href="http://forum.utorrent.com/profile.php?id=6653">Dumper</a>
								</li></ul>
							</div>

							<div ><p className="mb-0">Catalan</p>
								<ul>
									<li><a href="http://forum.utorrent.com/profile.php?id=893488">bartges90</a></li>
									<li><a href="http://forum.utorrent.com/profile.php?id=893904">fike</a></li>
									<li><a href="http://forum.utorrent.com/profile.php?id=892812">el_libre</a></li>
								</ul>
							</div>
							<div ><p className="mb-0">Chinese (Simplified)</p>
								<ul><li>
									<a href="http://forum.utorrent.com/profile.php?id=885666">Cye3s</a>
								</li></ul>
							</div>

							<div ><p className="mb-0">Chinese (Traditional)</p>
								<ul><li>
									<a href="http://forum.utorrent.com/profile.php?id=110">hin123</a>
								</li></ul>
							</div>
							<div ><p className="mb-0">Croatian</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=205713">ivek</a></li></ul>
							</div>
							<div ><p className="mb-0">Czech</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=3023">emc</a></li></ul>
							</div>
						</div>
						<div className="col-md-3">
							<div ><p className="mb-0">Dutch</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=98684">Domokun</a></li></ul>
							</div>

							<div ><p className="mb-0">Estonian</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=2179">pckilla</a></li></ul>
							</div>

							<div ><p className="mb-0">Finnish</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=9303">ettpok</a></li></ul>
							</div>

							<div ><p className="mb-0">French</p>
								<ul>
									<li><a href="http://forum.utorrent.com/profile.php?id=791128">Mal15</a></li>
									<li><a href="http://forum.utorrent.com/profile.php?id=835812">harlock59</a></li>
								</ul>
							</div>

							<div ><p className="mb-0">Gaeilge (Irish)</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=58672">seanan</a></li></ul>
							</div>

							<div ><p className="mb-0">Galician</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=852006">markooss</a></li></ul>
							</div>

							<div ><p className="mb-0">German</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=379114">kt_me</a></li></ul>
							</div>

							<div ><p className="mb-0">Greek</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=108893">mpqo</a></li></ul>
							</div>
							<div ><p className="mb-0">Hebrew</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=10764">aviramof</a></li></ul>
							</div>

							<div ><p className="mb-0">Indonesian</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=682660">rsmamoru</a></li></ul>
							</div>
							<div ><p className="mb-0">Italian</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=615246">Giangy94</a></li></ul>
							</div>
							<div ><p className="mb-0">Japanese</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=563936">shousetsu</a></li></ul>
							</div>
							<div ><p className="mb-0">Kabardian</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=828656">balqar.tama</a></li></ul>
							</div>
							<div ><p className="mb-0">Kazakh</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=112521">daur88</a></li></ul>
							</div>
						</div>
						<div className="col-md-3">
							<div ><p className="mb-0">Khmer</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=921908">piseth</a></li></ul>
							</div>

							<div ><p className="mb-0">Korean</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=304628">alphanis</a></li></ul>
							</div>

							<div ><p className="mb-0">Latvian</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=19137">Roronoa</a></li></ul>
							</div>

							<div ><p className="mb-0">Macedonian</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=934642">mareli</a></li></ul>
							</div>

							<div ><p className="mb-0">Malay</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=668512">frozenfrog</a></li></ul>
							</div>

							<div ><p className="mb-0">Mongolian</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=499106">bagi576</a></li></ul>
							</div>

							<div ><p className="mb-0">Nepali</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=934342">suniltheblue</a></li></ul>
							</div>

							<div ><p className="mb-0">Norwegian (Bokmal)</p>
								<ul>
									<li><a href="http://forum.utorrent.com/profile.php?id=886234">Colargol66</a></li>
									<li><a href="http://forum.utorrent.com/profile.php?id=117787">hmork</a></li>
								</ul>
							</div>

							<div ><p className="mb-0">Norwegian (Nynorsk)</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=67811">Vestrvegr</a></li></ul>
							</div>
							<div ><p className="mb-0">Polish</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=50416">gregul</a></li></ul>
							</div>

							<div ><p className="mb-0">Portuguese (Brazil)</p>
								<ul>
									<li><a href="http://forum.utorrent.com/profile.php?id=13024">xBellox</a></li>
									<li><a href="http://forum.utorrent.com/profile.php?id=873800">Dher</a></li>
								</ul>
							</div>
							<div ><p className="mb-0">Portuguese (Portugal)</p>
								<ul><li>
									<a href="http://forum.utorrent.com/profile.php?id=844498">mmodem</a>
								</li></ul>
							</div>
							<div ><p className="mb-0">Romanian</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=61078">muntealb</a></li></ul>
							</div>
							<div ><p className="mb-0">Russian</p>
								<ul><li>Dmitry</li></ul>
							</div>
						</div>
						<div className="col-md-3">
							<div ><p className="mb-0">Serbian (Cyrillic)</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=566784">Rancher</a></li></ul>
							</div>

							<div ><p className="mb-0">Serbian (Latin)</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=566784">Rancher2</a></li></ul>
							</div>

							<div ><p className="mb-0">Sinhala</p>
								<ul>
									<li><a href="http://forum.utorrent.com/profile.php?id=919022">kmdushyantha</a></li>
									<li><a href="http://forum.utorrent.com/profile.php?id=857613">pkavinda</a></li>
								</ul>
							</div>

							<div ><p className="mb-0">Slovak</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=10734">Lumir</a></li></ul>
							</div>

							<div ><p className="mb-0">Slovenian</p>
								<ul><li>
									<a href="http://forum.utorrent.com/profile.php?id=108320">damjang</a>
								</li></ul>
							</div>

							<div ><p className="mb-0">Spanish</p>
								<ul>
									<li><a href="http://forum.utorrent.com/profile.php?id=3848">sa69</a></li>
									<li><a href="http://forum.utorrent.com/profile.php?id=454">Chrono79</a></li>
								</ul>
							</div>

							<div ><p className="mb-0">Taiwan</p>
								<ul><li>
									<a href="http://forum.utorrent.com/profile.php?id=15684">JetLee</a>
								</li></ul>
							</div>

							<div ><p className="mb-0">Tatar</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=925896">irekhaz</a></li></ul>
							</div>

							<div ><p className="mb-0">Thai</p>
								<ul><li>
									<a href="http://forum.utorrent.com/profile.php?id=844616">warofdefeat</a>
								</li></ul>
							</div>

							<div ><p className="mb-0">Turkish</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=864338">muammerturkmen</a></li></ul>
							</div>

							<div ><p className="mb-0">Ukrainian</p>
								<ul><li><a href="http://forum.utorrent.com/profile.php?id=450546">rkononenko</a></li></ul>
							</div>

							<div ><p className="mb-0">Vietnamese</p>
								<ul>
									<li><a href="http://forum.utorrent.com/profile.php?id=903790">nhatthanhxx</a></li>
									<li><a href="http://forum.utorrent.com/profile.php?id=19214">epidemic</a></li>
								</ul>
							</div>

							<div ><p className="mb-0">Welsh</p>
								<ul>
									<li><a href="http://forum.utorrent.com/profile.php?id=215206">anweledig</a></li>
									<li><a href="http://forum.utorrent.com/profile.php?id=877502">Golygydd</a></li>
								</ul>
							</div>
						</div>
				</div>
        	}
        </ModalBody>

      </Modal>
		)
}
export { DownloadModals }
export default DownloadsWindows
