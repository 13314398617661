import axios from "axios"
import testData from "./testData.json"


function fetchCherryData(product, os, locale){
	return axios.get('https://download-new.utorrent.com/client-versions.json')
	.then(res => {
		return parseData(res.data, product, os, locale)
		
	})
	.catch( e =>{
		return parseData(testData, product, os, locale)
	})

}

function parseData(data, product, os, locale){
	let versions = data[product]
	let latestVersion = versions.filter(version => (
		version["cn"] === "*" &&
		version["osperc"] === 100 &&
		version["cnperc"] === 100 &&
		version["os"] === os &&
		version["track"] === "stable"
		))
	latestVersion = latestVersion[0]
	let fileSize = getFileSize(latestVersion["file-size"])
	let version = getVersion(latestVersion["target"])
	locale = locale.replace('_', '-')
	let lastModified = getLastModified(latestVersion["last-modified"], locale)
	return ({
		fileSize:fileSize,
		version:version,
		lastModified:lastModified
	})
}

export default fetchCherryData;

function getLastModified(date, locale){

	let d = new Date(date)
	return d.toLocaleDateString(locale)

}

function getVersion(target){
	let separatorIndex = target.lastIndexOf('.')
	let version = target.slice(0, separatorIndex)
	let build = target.slice(separatorIndex+1)
	return (version + " Build " + build)
}

function getFileSize(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

